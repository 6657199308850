import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */
/* @jsx mdx */
import { EuiSpacer } from '@elastic/eui';
import SuccessBox from 'components/Web_User_Interface/1080p_Series/Alarm/Alarm_Server/SuccessBox';
import ASTable from 'components/Web_User_Interface/1080p_Series/Alarm/Alarm_Server/asTable';
import PrimaryBox from 'components/Web_User_Interface/1080p_Series/Alarm/Alarm_Server/PrimaryBox';
import BreadCrumbs from 'components/Layout/BreadCrumbs';
import SEOHelmet from 'components/Layout/SEOHelmet';
export const _frontmatter = {
  "title": "Alarm Menu // Alarm Server",
  "path": "/Web_User_Interface/1080p_Series/Alarm/Alarm_Server/",
  "dateChanged": "2020-03-12",
  "author": "Mike Polinowski",
  "excerpt": "Notify your Home Automation System in case of a Motion Detection",
  "image": "./WebUI_1080p_SearchThumb_Smarthome_Alarmserver.png",
  "social": "/images/Search/WebUI_1080p_SearchThumb_Smarthome_Alarmserver.webp",
  "toc": "/images/Search/TOC_Icons/Wiki_Tiles_WebUI_white.webp",
  "chapter": "1080p Web User Interface"
};
const layoutProps = {
  _frontmatter
};
const MDXLayout = "wrapper";
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">



    <SEOHelmet title='Smarthome Menu // Alarm Server' dateChanged='2020-10-23' author='Mike Polinowski' tag='INSTAR IP Camera' description='Notify your Home Automation System in case of a Motion Detection' image='/images/Search/WebUI_1080p_SearchThumb_Smarthome_Alarmserver.png' twitter='/images/Search/WebUI_1080p_SearchThumb_Smarthome_Alarmserver.webp' location={props.location.pathname} mdxType="SEOHelmet" />
    <BreadCrumbs locationBC={props.location} locationEN={props.location.pathname} locationDE='/de/Web_User_Interface/1080p_Serie/Alarm/Alarm_Server/' locationFR='/fr/Web_User_Interface/1080p_Series/Alarm/Alarm_Server/' crumbLabel="Alarm Server" mdxType="BreadCrumbs" />
    <h1 {...{
      "id": "1080p-web-user-interface",
      "style": {
        "position": "relative"
      }
    }}><a parentName="h1" {...{
        "href": "#1080p-web-user-interface",
        "aria-label": "1080p web user interface permalink",
        "className": "header-link-icon before"
      }}><svg parentName="a" {...{
          "aria-hidden": "true",
          "focusable": "false",
          "height": "16",
          "version": "1.1",
          "viewBox": "0 0 16 16",
          "width": "16"
        }}><path parentName="svg" {...{
            "fillRule": "evenodd",
            "d": "M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z"
          }}></path></svg></a>{`1080p Web User Interface`}</h1>
    <h2 {...{
      "id": "smarthome-menu",
      "style": {
        "position": "relative"
      }
    }}><a parentName="h2" {...{
        "href": "#smarthome-menu",
        "aria-label": "smarthome menu permalink",
        "className": "header-link-icon before"
      }}><svg parentName="a" {...{
          "aria-hidden": "true",
          "focusable": "false",
          "height": "16",
          "version": "1.1",
          "viewBox": "0 0 16 16",
          "width": "16"
        }}><path parentName="svg" {...{
            "fillRule": "evenodd",
            "d": "M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z"
          }}></path></svg></a>{`Smarthome Menu`}</h2>
    <EuiSpacer mdxType="EuiSpacer" />
    <SuccessBox mdxType="SuccessBox" />
    <EuiSpacer mdxType="EuiSpacer" />
    <p><span parentName="p" {...{
        "className": "gatsby-resp-image-wrapper",
        "style": {
          "position": "relative",
          "display": "block",
          "marginLeft": "auto",
          "marginRight": "auto",
          "maxWidth": "920px"
        }
      }}>{`
      `}<a parentName="span" {...{
          "className": "gatsby-resp-image-link",
          "href": "/en/static/e57c8caeb25ac6c98a41f223b0950eed/8dd93/1080p_Settings_Alarm_Alarmserver.png",
          "style": {
            "display": "block"
          },
          "target": "_blank",
          "rel": "noopener"
        }}>{`
    `}<span parentName="a" {...{
            "className": "gatsby-resp-image-background-image",
            "style": {
              "paddingBottom": "70.43478260869564%",
              "position": "relative",
              "bottom": "0",
              "left": "0",
              "backgroundImage": "url('data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABQAAAAOCAIAAACgpqunAAAACXBIWXMAAA7EAAAOxAGVKw4bAAACyUlEQVQoz1VT3UsUURSfeogyX8w+0AQVTMOPLTMkzI8lWd1dDQPrIdMyy9CwrHXn3juzjh+zu6lIjhZBkIVULKZUDz4IGWFZ0FPQ+g9k+qq2H+7O7szcEzNbRj/OPXAv/M7vdw7nMgghjBEhhON4F4exe6Lr0ZfOh0vXxj9ux/Xxxa7HXx3eJwJPBKG/ra2t0HSsuOQkwyLsRBghJIpifZ394qWWH5vg/xnyr4aX17aWV7f8q1vfV4IrQXg9M+NsaXa2tLbWNdaXni7PL2JYTO46MULY4/FUVJqt1nqqqaFggFINQKOaQjUVtHhAgc9vXk7e6Rhu510NzZzN3GA6wXCEcyKMMRZF0W6rddy+QSmNx2IAoCpKNBpVVTUekwFg7sWrblvz/Y4Oqb273nz5uOkUQwjXw2KEsej22C3mrqsXAEDTVJ2sqrFYTJblcDgMAFMTD972nPeL1d+kK9N9rs4zJQzCxMFiFmGvYbumxkYpDYVC0WiUUgoGNFWvNT/77Na5kubS/LHG7E83M+fbCvSB6cpGz5VVVVarTVGUUChEKdUMUKBKRI5E1iennx/OyDmaU3joYLopY5+rLJVxstjh/EeutdoAQJblbdmEfwAYHRlNS92fl5uXkpKSnJRUnZXEsIiwmGCsk8sryi0WC6VUURQAUIyBbffsHhlOTTuQeSR7b/LuPbt2lqfvYAjH6YGJMW17U1NTQjmRNzbWg4HAxq9NAHg6O1XdVFt2trKwuCi3IC8jK4vh/oLneZZlfT4fAGx7jmsQVUCNRABgYWGBd/GiW+wfGOjv19N/ZISQz+dL2FYUFajmXwm+86+vvV+Kflicn5tDGLtFcXBgUMfAIMPzXCI4juvt7fV4vWOSJEnjfyBJ45Jxn5i4NzRkCOqyCTAOVt9th74nhHe5MMc5WX3hjK+ilxP0I/QKQl9fX4IpCPobIeQ3+GH1ihtCtQIAAAAASUVORK5CYII=')",
              "backgroundSize": "cover",
              "display": "block"
            }
          }}></span>{`
  `}<picture parentName="a">{`
          `}<source parentName="picture" {...{
              "srcSet": ["/en/static/e57c8caeb25ac6c98a41f223b0950eed/e4706/1080p_Settings_Alarm_Alarmserver.avif 230w", "/en/static/e57c8caeb25ac6c98a41f223b0950eed/d1af7/1080p_Settings_Alarm_Alarmserver.avif 460w", "/en/static/e57c8caeb25ac6c98a41f223b0950eed/7f308/1080p_Settings_Alarm_Alarmserver.avif 920w", "/en/static/e57c8caeb25ac6c98a41f223b0950eed/19805/1080p_Settings_Alarm_Alarmserver.avif 1369w"],
              "sizes": "(max-width: 920px) 100vw, 920px",
              "type": "image/avif"
            }}></source><source parentName="picture" {...{
              "srcSet": ["/en/static/e57c8caeb25ac6c98a41f223b0950eed/a0b58/1080p_Settings_Alarm_Alarmserver.webp 230w", "/en/static/e57c8caeb25ac6c98a41f223b0950eed/bc10c/1080p_Settings_Alarm_Alarmserver.webp 460w", "/en/static/e57c8caeb25ac6c98a41f223b0950eed/966d8/1080p_Settings_Alarm_Alarmserver.webp 920w", "/en/static/e57c8caeb25ac6c98a41f223b0950eed/61eb3/1080p_Settings_Alarm_Alarmserver.webp 1369w"],
              "sizes": "(max-width: 920px) 100vw, 920px",
              "type": "image/webp"
            }}></source>{`
          `}<source parentName="picture" {...{
              "srcSet": ["/en/static/e57c8caeb25ac6c98a41f223b0950eed/81c8e/1080p_Settings_Alarm_Alarmserver.png 230w", "/en/static/e57c8caeb25ac6c98a41f223b0950eed/08a84/1080p_Settings_Alarm_Alarmserver.png 460w", "/en/static/e57c8caeb25ac6c98a41f223b0950eed/c0255/1080p_Settings_Alarm_Alarmserver.png 920w", "/en/static/e57c8caeb25ac6c98a41f223b0950eed/8dd93/1080p_Settings_Alarm_Alarmserver.png 1369w"],
              "sizes": "(max-width: 920px) 100vw, 920px",
              "type": "image/png"
            }}></source>{`
          `}<img parentName="picture" {...{
              "className": "gatsby-resp-image-image",
              "src": "/en/static/e57c8caeb25ac6c98a41f223b0950eed/c0255/1080p_Settings_Alarm_Alarmserver.png",
              "alt": "Web User Interface - 1080p Series - Alarm Server",
              "title": "Web User Interface - 1080p Series - Alarm Server",
              "loading": "lazy",
              "decoding": "async",
              "style": {
                "width": "100%",
                "height": "100%",
                "margin": "0",
                "verticalAlign": "middle",
                "position": "absolute",
                "top": "0",
                "left": "0"
              }
            }}></img>{`
        `}</picture>{`
  `}</a>{`
    `}</span></p>
    <EuiSpacer mdxType="EuiSpacer" />
    <ASTable mdxType="ASTable" />
    <EuiSpacer mdxType="EuiSpacer" />
    {/* |   |   |
     |---|---|
     | __Contact Alarm Server__ | Activate the Alarm Server Function |
     | __Cooldown Interval__ | How long should the server wait to re-notify you if the alarm persists (1s - 60s) |
     | __Alarm Server Address__ | Type in the IP Address of your Alarm Server - e.g. your FHEM Server. |
     | __Server Port__ | Type in the TCP Port that your Alarm Server is listening on. |
     | __Server Path__ | Type in the URL that your Server expects to see. |
     | __Encryption__ | Activate HTTPS SSL encryption for the web request |
     | __POST Request__ | Change the default GET to a POST request |
     | __Parameter__ | You can add optional parameter to the URL. |
     | __Append Alarm Trigger__ | The alarm trigger (e.g. "motion detection area 1" or "audio detection") will be added to the HTTP request in form of a query. * |
     | __Authentication__ | Add your Server Login to the URL. | */}
    <PrimaryBox mdxType="PrimaryBox" />
    <EuiSpacer mdxType="EuiSpacer" />
    <h2 {...{
      "id": "alarmserver-introduction",
      "style": {
        "position": "relative"
      }
    }}><a parentName="h2" {...{
        "href": "#alarmserver-introduction",
        "aria-label": "alarmserver introduction permalink",
        "className": "header-link-icon before"
      }}><svg parentName="a" {...{
          "aria-hidden": "true",
          "focusable": "false",
          "height": "16",
          "version": "1.1",
          "viewBox": "0 0 16 16",
          "width": "16"
        }}><path parentName="svg" {...{
            "fillRule": "evenodd",
            "d": "M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z"
          }}></path></svg></a>{`Alarmserver Introduction`}</h2>
    <ul>
      <li parentName="ul"><a parentName="li" {...{
          "href": "/en/Motion_Detection/Alarm_Server/"
        }}>{`INSTAR Alarmserver`}</a></li>
    </ul>
    <h2 {...{
      "id": "alarmserver-use-cases",
      "style": {
        "position": "relative"
      }
    }}><a parentName="h2" {...{
        "href": "#alarmserver-use-cases",
        "aria-label": "alarmserver use cases permalink",
        "className": "header-link-icon before"
      }}><svg parentName="a" {...{
          "aria-hidden": "true",
          "focusable": "false",
          "height": "16",
          "version": "1.1",
          "viewBox": "0 0 16 16",
          "width": "16"
        }}><path parentName="svg" {...{
            "fillRule": "evenodd",
            "d": "M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z"
          }}></path></svg></a>{`Alarmserver Use-Cases:`}</h2>
    <ul>
      <li parentName="ul"><a parentName="li" {...{
          "href": "/en/Software/Other_Platforms/Loxone_Miniserver/#use-the-loxone-miniserver-as-alarm-server"
        }}>{`Loxone Miniserver`}</a></li>
      <li parentName="ul"><a parentName="li" {...{
          "href": "/en/Software/Other_Platforms/Homematic_IP/#using-the-homematic-ccu3-as-alarmserver-for-our-camera"
        }}>{`Homematic CCU3`}</a></li>
      <li parentName="ul"><a parentName="li" {...{
          "href": "/en/Advanced_User/Homematic_CCU3_and_RedMatic/#instar-ip-camera---homematic"
        }}>{`Homematic & RedMatic`}</a></li>
      <li parentName="ul"><a parentName="li" {...{
          "href": "/en/Software/Other_Platforms/Homee/#camera-alarmserver-and-homee-webhook"
        }}>{`homee`}</a></li>
      <li parentName="ul"><a parentName="li" {...{
          "href": "/en/Software/Other_Platforms/Athom_Homey/#notifying-homey-when-the-camera-alarm-is-triggered-alarm-server"
        }}>{`Homey`}</a></li>
      <li parentName="ul"><a parentName="li" {...{
          "href": "/en/Advanced_User/IFTTT_as_Alarmserver/"
        }}>{`IFTTT`}</a></li>
      <li parentName="ul"><a parentName="li" {...{
          "href": "/en/Software/Linux/Home_Assistant/#using-home-assistant-as-alarmserver-for-your-instar-camera"
        }}>{`Home Assistant`}</a></li>
      <li parentName="ul"><a parentName="li" {...{
          "href": "/en/Advanced_User/Node-RED_and_MQTT/Projects/Alarm_Recording_and_Nightvision/"
        }}>{`Node-RED under Windows`}</a></li>
      <li parentName="ul"><a parentName="li" {...{
          "href": "/en/Advanced_User/openHABian_on_Raspberry_Pi/Projects/Alarm_Recording_and_Nightvision/"
        }}>{`Node-RED auf einem Raspberry Pi (openHabian)`}</a></li>
    </ul>
    <h2 {...{
      "id": "working-with-alarmserver-queries",
      "style": {
        "position": "relative"
      }
    }}><a parentName="h2" {...{
        "href": "#working-with-alarmserver-queries",
        "aria-label": "working with alarmserver queries permalink",
        "className": "header-link-icon before"
      }}><svg parentName="a" {...{
          "aria-hidden": "true",
          "focusable": "false",
          "height": "16",
          "version": "1.1",
          "viewBox": "0 0 16 16",
          "width": "16"
        }}><path parentName="svg" {...{
            "fillRule": "evenodd",
            "d": "M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z"
          }}></path></svg></a>{`Working with Alarmserver Queries:`}</h2>
    <ul>
      <li parentName="ul"><a parentName="li" {...{
          "href": "/en/Advanced_User/Alarmserver_Queries_for_your_Smarthome/"
        }}>{`Alarmserver Queries for your Smarthome`}</a></li>
    </ul>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      